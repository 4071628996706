<template>
  <div>
    <a-descriptions
      :column="1"
      class="king-descriptions-two-columns-max-10"
    >
      <a-descriptions-item label="逝者父亲是否健在">{{ familyInfo.father_living }}</a-descriptions-item>
      <a-descriptions-item label="逝者母亲是否健在">{{ familyInfo.mother_living }}</a-descriptions-item>
      <a-descriptions-item label="逝者配偶是否健在">{{ familyInfo.partner_status }}</a-descriptions-item>
      <a-descriptions-item label="逝者子女情况">{{ familyInfo.children_info }}</a-descriptions-item>
    </a-descriptions>
    <a-divider v-if="familyInfo.family_info && familyInfo.family_info.length !== 0" />
    <a-table
        :columns="columns"
        :data-source="familyList"
        :loading="loading"
        :pagination="false"
        row-key="id"
    >
    </a-table>
  </div>
</template>

<script>

import { findServiceOrderDeathFamilyInfo } from '@/api/order'

export default {
  name: 'FamilyInfo',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      familyInfo: {},
      loading: false,
      familyList:[],
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '姓名',
          width: 90,
          dataIndex: 'name'
        },
        {
          title:'性别',
          width: 60,
          dataIndex: 'sex_text'
        },
        {
          title:'联系方式',
          width: 90,
          dataIndex: 'phone_number'
        },
        {
          title:'身份证号',
          width: 120,
          dataIndex: 'id_no'
        }
      ]
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      findServiceOrderDeathFamilyInfo(this.id).then(res => {
        if (res.code === 0) {
          this.familyInfo = res.data
          this.familyList = res.data.family_info
        }
        this.loading = false
      })
    }
  }
}
</script>
