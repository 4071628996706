<template>
  <a-modal
    v-model="isShow"
    :footer="null"
    width="800px"
    title="预览视频"
  >
    <div v-if="videos.length > 0">
      <video
        style="width: 100%; height: 500px"
        controls
        type="video/mp4"
        id="videos"
        v-for="(item, index) in videos"
        :key="index"
        :src="item.url"
      >
        您的浏览器不支持video标签哦，请联系管理员
      </video>
    </div>
    <div v-if="video">
      <video
        style="width: 100%; height: 500px"
        controls
        type="video/mp4"
        id="video"
        :src="video"
      >
        您的浏览器不支持video标签哦，请联系管理员
      </video>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'PreviewVideo',
  props: {
    // 是否预览
    visible: {
      type: Boolean,
      default: false
    },

    video: {
      type: String,
      default: ''
    },

    // 预览视频地址
    videos: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
  },
  watch: {
    visible(val) {
      if (val === false) {
        const video = document.getElementById('video')
        const videos = document.getElementById('videos')
        if (video) {
          video.pause()
        }
        if (videos) {
          videos.pause()
        }
      }
    }
  }
}

</script>
