<template>
  <div>
    <a-row>
      <a-col :span="12">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-10"
        >
          <a-descriptions-item label="姓名">{{ basicInfo.name | basicInfoNameFilter }}</a-descriptions-item>
          <a-descriptions-item label="身份证号">{{ basicInfo.identity_card_no }}</a-descriptions-item>
          <a-descriptions-item label="性别">{{ basicInfo.sex }}</a-descriptions-item>
          <a-descriptions-item label="年龄">{{ basicInfo.age }}</a-descriptions-item>
          <a-descriptions-item label="宗教信仰">{{ basicInfo.religion }}</a-descriptions-item>
          <a-descriptions-item label="出生时间">{{ basicInfo.birth_day }}</a-descriptions-item>
          <a-descriptions-item label="是否已逝世">{{ basicInfo.dead }}</a-descriptions-item>
          <a-descriptions-item v-if="basicInfo.is_dead" label="逝世时间">{{ basicInfo.death_date }}</a-descriptions-item>
          <a-descriptions-item v-if="basicInfo.is_dead" label="逝世原因">{{ basicInfo.dead_reason }}</a-descriptions-item>
          <a-descriptions-item v-if="basicInfo.is_dead" label="逝者身上是否有贵重物品">{{ basicInfo.has_valuables ? '有' : '无' }}</a-descriptions-item>
          <a-descriptions-item v-if="basicInfo.has_valuables">{{ basicInfo.valuables_content }}</a-descriptions-item>
          <a-descriptions-item v-if="basicInfo.is_dead" label="逝者身上是否有伤口破损">{{ basicInfo.damaged ? '有' : '无' }}</a-descriptions-item>
          <a-descriptions-item v-if="basicInfo.damaged">{{ basicInfo.damage_desc }}</a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="12">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-10"
        >
          <a-descriptions-item label="是否为党员">{{ basicInfo.party_member }}</a-descriptions-item>
          <a-descriptions-item label="逝世职业">{{ basicInfo.occupation }}</a-descriptions-item>
          <a-descriptions-item label="家庭住址">{{ basicInfo.address }}</a-descriptions-item>
          <a-descriptions-item label="肖像照片">
            <div v-if="basicInfo.images && defaultImages.length > 0">
              <div class="custom-flex custom-flex-wrap">
                <div v-for="(img, index) in basicInfo.images" :key="index">
                  <img :src="img.url" @click="showImg(img.url)" width="50px" height="50px" style="margin: 5px">
                </div>
              </div>
            </div>
            <span v-else>暂无图片</span>
          </a-descriptions-item>
          <a-descriptions-item label="视频">
            <div v-if="basicInfo.videos && defaultVideos.length > 0">
              <div class="custom-flex custom-flex-wrap">
                <div v-for="(img, index) in basicInfo.videos" :key="index" class="video">
                  <img src="/temp/logo.png" width="50px" height="50px" @click="showVideo(img.url)">
                </div>
              </div>
            </div>
            <span v-else>暂无视频</span>
          </a-descriptions-item>
          <a-descriptions-item label="逝者生平简介">
            <a @click="showIntroduction">查看详情</a>
            <introduction
              v-if="isShowIntroduction"
              :introduction="basicInfo"
              :visible.sync="isShowIntroduction"
            />
          </a-descriptions-item>
          <a-descriptions-item label="证件资料">
            <div v-if="basicInfo.documents && defaultDocuments.length > 0">
              <div class="custom-flex custom-flex-wrap">
                <div v-for="(img, index) in basicInfo.documents" :key="index">
                  <img :src="img.url" @click="showImg(img.url)" width="50px" height="50px" style="margin: 5px">
                </div>
              </div>
            </div>
            <span v-else>暂无图片</span>
          </a-descriptions-item>
          <a-descriptions-item label="是否行三跪九叩首大礼">{{ basicInfo.san_gui_jiu_kou_text }}</a-descriptions-item>
          <a-descriptions-item label="家属取抽身布">{{ basicInfo.pull_out_cloth_text }}</a-descriptions-item>
        </a-descriptions>
        <!-- 预览图片 -->
        <preview-image
          :visible.sync="previewImgVisible"
          :image.sync="previewImage"
        />
        <!-- 预览视频 -->
        <preview-video
          :visible.sync="previewVideoVisible"
          :video.sync="previewVideo"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import PreviewImage from '@/components/PreviewImage'
import PreviewVideo from '@/components/PreviewVideo'
import introduction from '@/views/service_order_deaths/system/show/Introduction'
import { findServiceOrderDeathBasicInfo } from '@/api/order'

export default {
  name: 'BasicInfo',
  components: {
    PreviewImage,
    PreviewVideo,
    introduction
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'BasicInfo' }),
      previewImgVisible: false,
      previewImage: '',
      previewVideoVisible: false,
      previewVideo: '',
      isShowIntroduction: false,
      basicInfo: {},
      defaultImages: [],
      defaultVideos: [],
      defaultDocuments: []
    }
  },
  filters: {
    operatorNameFilter(value) {
      if (value && value.length > 10) {
        return value.substring(0, 10) + '...'
      }
      return value
    },
    basicInfoNameFilter(value) {
      if (value && value.length > 20) {
        return value.substring(0, 20) + '...'
      }
      return value
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findServiceOrderDeathBasicInfo(this.id).then(res => {
        if (res.code === 0) {
          this.basicInfo = res.data
          this.defaultImages = res.data.images
          this.defaultVideos = res.data.videos
          this.defaultDocuments = res.data.documents
        }
        this.loading = false
      })
    },
    showImg(url) {
      this.previewImgVisible = true
      this.previewImage = url
    },
    showVideo(basicInfo) {
      this.previewVideoVisible = true
      this.previewVideo = basicInfo.videos
    },
    showIntroduction() {
      this.isShowIntroduction = true
    },
    cancelEditImg() {
      this.submitting = false
      this.isShowImgEdit = false
    },
    cancelEditVideo() {
      this.submitting = false
      this.isShowVideoEdit = false
    },
    cancelShowIntroduction() {
      this.isShowIntroduction = false
    }
  }
}
</script>
<style lang= "less" scoped>
.edit-class {
  margin: 10px;
}

.video {
  border: 1px solid #a7b6be36;
  padding: 5px;
  margin: 5px;
}
</style>
