<template>
  <div>
    <a-tabs
      type="card"
      v-decorator="['tags', {
      }]"
      @change="handleChange"
    >
      <a-tab-pane key="basic" tab="基本信息" class="tab-pane-scroll">
        <basic-info
          :id="id"
          ref="child"
        />
      </a-tab-pane>
      <a-tab-pane key="family" tab="家属信息" class="tab-pane-scroll">
        <family-info
          :id="id"
          ref="child"
        />
      </a-tab-pane>
      <a-tab-pane key="genealogy" tab="家族谱" class="tab-pane-scroll">
        <genealogy ref="child" :id="id" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import BasicInfo from '@/views/orders/system/deaths/BasicInfo'
import familyInfo from '@/views/orders/system/deaths/FamilyInfo'
import Genealogy from '@/components/genealogy/genealogy'
export default {
  name: 'ShowDeathInfo',
  components: {
    BasicInfo,
    familyInfo,
    Genealogy
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      loading: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleChange(e) {
      return {
        tab: e.tab,
        index: e.index
      }
    },
    fetchData() {
      this.$refs.child.fetchData()
    }
  }
}
</script>

<style lang="less" scoped>
.custom-blue {
  cursor: pointer;
}

.tab-pane-scroll {
  height: 443px;
  overflow: auto;
}
</style>
