<template>
  <div class="tree">
    <div style="display: flex;flex-direction: row;" v-for="level in treeData.length">
      <div v-for="(item,index) in treeData[level-1]" class="margin-top-50">
        <div v-if="item.exits === false" class="tree-item">
          <div class="tree-node">
            <div class="name" style="visibility: hidden;" />
            <div class="number" style="visibility: hidden;" />
            <div
              class="v-line top"
              v-if="item.level > 1 && item.end === false"
              :style="{width:`112%`}"
            />
          </div>
        </div>
        <div v-else class="tree-item">
          <div class="tree-node" :class="{active:true,current:true}" :style="{'line-height': level === 1 ? '40px' : '20px'}">
            <div v-if="item.has_partner" class="name">
              <div>{{ item.call != '' ? item.call : ' ' }}</div>
              <div>{{ item.name }}{{'(' + item.sex_display + ')'}}{{item.dead ? '-(已逝)' : '' }}</div>
            </div>
            <div v-if="item.has_partner" class="number">
              <div>{{ item.partner.call }}</div>
              <div>
                {{ item.partner.name + '(' + item.partner.sex_display + ')' }}{{item.partner.dead ? '-(已逝)' : '' }}
              </div>
            </div>
            <div v-if="!item.has_partner" class="single_name">
              <div>{{ item.call != '' ? item.call : ' ' }}</div>
              <div>{{ item.name }}{{'(' + item.sex_display + ')' }}{{item.dead ? '-(已逝)' : '' }}</div>
            </div>

            <div class="line top" v-if="item.level > 1" />

            <div
              class="v-line top"
              v-if="item.level > 1 && item.end === false"
              :style="{width:`112%`}"
            />

            <div class="line bottom" v-if="item.has_children && item.sex_slug === 'male'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { findServiceOrderDeathGenealogy } from '@/api/order'

export default {
  name: 'TreeViewItem',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      treeData: []
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findServiceOrderDeathGenealogy(this.id).then(res => {
        if (res.code === 0) {
          this.treeData = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.margin-top-50{
  margin-top: 50px;
}
.tree {
  position: relative;
  //overflow: auto;
  //height: 500px;

  .no-style {
    color: #FF6633;
    font-size: 12px;
    font-weight: 500;
    text-shadow: 0px 0px 11px rgba(168, 224, 225, 0.57);
  }
}

.tree-item {
  display: flex;
  .tree-node {
    display: flex;
    height: 40px;
    //line-height: 40px;
    //font-family: PingFangSC-Medium, PingFang SC;
    text-align: center;
    margin: 0 36px 13px 0;
    position: relative;

    .name {
      width: 150px;
      background: #A78744;
      box-shadow: 0px 0px 11px 0px rgba(168, 224, 225, 0.57), 0px 3px 9px -8px rgba(195, 199, 208, 1);
      font-size: 11px;
      font-weight: 500;
      color: #FFFFFF;
      text-shadow: 0px 0px 11px rgba(168, 224, 225, 0.57);
      position: relative;
      border-radius: 2px 0 0 2px;
      padding: 0 5px;

      text {
        display: inline-block;
        width: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .death_name {
      width: 150px;
      background: #333333;
      box-shadow: 0px 0px 11px 0px rgba(168, 224, 225, 0.57), 0px 3px 9px -8px rgba(195, 199, 208, 1);
      font-size: 11px;
      font-weight: 500;
      color: white;
      text-shadow: 0px 0px 11px rgba(168, 224, 225, 0.57);
      position: relative;
      border-radius: 2px 0 0 2px;
      padding: 0 5px;

      text {
        display: inline-block;
        width: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .single_name {
      width: 300px;
      background: #A78744;
      box-shadow: 0px 0px 11px 0px rgba(168, 224, 225, 0.57), 0px 3px 9px -8px rgba(195, 199, 208, 1);
      font-size: 11px;
      font-weight: 500;
      color: white;
      text-shadow: 0px 0px 11px rgba(168, 224, 225, 0.57);
      position: relative;
      border-radius: 2px 0 0 2px;
      padding: 0 5px;

      text {
        display: inline-block;
        width: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

      .number {
        width: 150px;
        box-shadow: 0px 0px 11px 0px rgba(168, 224, 225, 0.57), 0px 3px 9px -8px rgba(195, 199, 208, 1);
        border-radius: 0px 5px 5px 0px;
        font-size: 12px;
        font-family: Futura-Medium, Futura;
        font-weight: 500;
        color: #A78744;
        border: 1px solid #A78744;
        text-shadow: 0px 0px 22px rgba(168, 224, 225, 0.57);
        position: relative;

      // &:before {
      // 	content: '';
      // 	position: absolute;
      // 	z-index: 1;
      // 	width: 0;
      // 	height: 0;
      // 	left: 0;
      // 	top: calc(50% - 7.5px);
      // 	border-width: 7.5px 7.5px 7.5px 7.5px;
      // 	border-style: solid;
      // 	border-color: transparent transparent transparent #A78744;
      // }
    }

    .line {
      position: absolute;
      display: block;
      width: 1px;
      height: 35px;
      background: #A78744;

      &.bottom {
        left: 50%;
        top: 90%;
        width: 2px;
      }

      &.top {
        left: 50%;
        top: -88%;
        width: 2px;
      }

      &.before {
        left: -15px;
        top: 50%;
        width: 1px;
      }

      &.after {
        right: -15px;
        top: 50%;
      }
    }

    .v-line {
      position: absolute;
      display: block;
      height: 2px;
      background: #A78744;
      left: 50%;

      &.top {
        top: -88%;
      }
      &.up {
        bottom: 50%;
      }

      &.down {
        top: -50%;
      }
    }

    &.current {
      .name {
        background: #A78744;
        color: #FFFFFF;
        box-shadow: 0px 4px 20px 0px rgba(255, 169, 100, 0.65);
      }

      .number {
        background: #FFFFFF;
        // color: #FF6633;
        box-shadow: 0px 4px 20px 0px rgba(255, 169, 100, 0.65);
        border: 1px solid #A78744;

        &:before {
          border-color: transparent transparent transparent #A78744;
        }
      }
    }
  }

  .s-tree {
    position: absolute;
    z-index: 1;
    top: 0;
  }
}
</style>
